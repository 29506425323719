// ToastContext.js
import React, { createContext, useContext, useState, useRef } from "react";
import { Toast } from "primereact/toast";

const ToastContext = createContext();

export const ToastProvider = ({ children }) => {
  const toastRef = useRef(null);
  const [position, setPosition] = useState("top-right");

  const addToast = (options) => {
    toastRef.current.show(options);
  };

  const addToastSuccess = (options) => {
    setPosition(options.position);
    toastRef.current.show({
      severity: "success",
      summary: options.summary,
      detail: options.detail,
      life: 3000,
    });
  };

  const addToastError = (options) => {
    setPosition(options.position);
    toastRef.current.show({
      severity: "error",
      summary: options.summary,
      detail: options.detail,
      life: 3000,
    });
  };

  const addToastWarn = (options) => {
    setPosition(options.position);
    toastRef.current.show({
      severity: "warn",
      summary: options.summary,
      detail: options.detail,
      life: 3000,
    });
  };

  const addToastInfo = (options) => {
    setPosition(options.position);
    toastRef.current.show({
      severity: "info",
      summary: options.summary,
      detail: options.detail,
      life: 3000,
    });
  };

  return (
    <ToastContext.Provider
      value={{
        addToast,
        addToastSuccess,
        addToastError,
        addToastWarn,
        addToastInfo,
      }}
    >
      {children}
      <Toast ref={toastRef} position={position} />
    </ToastContext.Provider>
  );
};

export const useToast = () => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error("useToast must be used within a ToastProvider");
  }
  return context;
};
